* {
  box-sizing: border-box;
}

div,
p,
ul,
li,
ol,
table,
td {
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  color: #333;
  font-family: Raleway, sans-serif;
  font-size: 1.6rem;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}
