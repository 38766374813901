@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content_container {
  height: 100vh;
  background: url("./assets/bg.webp");
  background-size: cover;
  display: flex;
  color: #f7f7f7;
  flex-direction: column;
  padding: 0 4vw;
}

.intro__content {
  border: none;
  color: #f7f7f7;
  line-height: 1;
  text-align: center;
  animation: fadeIn 0.7s ease-in-out;
}

.intro__img {
  border: 3px solid;
  color: #f7f7f7;
  border-radius: 50%;
}

.intro__title {
  margin: 0 0 1.6rem;
  font-weight: 100;
  font-size: 4rem;
  line-height: 1;
  color: #f7f7f7;
}

.intro-text__item {
  font-weight: 400;
  padding: 1rem 8rem;
  min-width: 2.2rem;
}

.intro-text__container {
  display: inline-block;
  text-align: center;
  background: none;
}

.intro__sub-title {
  font-weight: 80;
  padding: 1rem;
}

.header__content {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0.8rem 0;
  margin-bottom: 8rem;
}

.header_link {
  text-decoration: none;
  background: none;
  border: none;
  color: #f7f7f7;
  transition: all 0.2s;
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1;
  padding: 3.2rem 1rem;
}

.contacts-item__box {
  border: none;
  color: #f7f7f7;
  line-height: 1;
  animation: fadeIn 0.7s ease-in-out;
  font-size: 1.8rem;
  text-align: center;
}

.contacts-item__title {
  font-weight: 100;
  font-size: 3rem;
  margin-bottom: 2.6rem;
}

.contacts-item__text {
  margin-top: 8px;
  margin-top: 1.6rem;
  font-size: 2.4rem;
}

.contacts-item__link {
  color: #955cc8;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: #f7f7f7;
  }
}

.contacts-icon-spacing {
  margin-right: 4px;
}

.about-me-text__content {
  text-align: center;
  line-height: 1;
  color: #f7f7f7;
  animation: fadeIn 0.7s ease-in-out;
}

.about-me-item__title {
  font-weight: 100;
  font-size: 4rem;
}

.about-me-text__item {
  padding: 8px 0;
  font-size: 1.8rem;
  font-weight: 100;
  line-height: 18px;
}

.about-me__sub-title {
  font-weight: 80;
  padding: 8px;
}

.not-found__content {
  flex-direction: column;
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
}

.not-found-item-link-color {
  margin-top: 1.6rem;
  color: #955cc8;
  text-decoration: none;
}

@media (min-width: 600px) {
  .content_container {
    padding: 0 20vw;
  }
  .about-me-text__item {
    padding: 1rem 0;
    font-size: 1.8rem;
    font-weight: 100;
    line-height: 2.5rem;
  }
  .intro__title {
    font-size: 8rem;
  }
  .intro-text__container {
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selected {
  color: #955cc8;
}
